import React from "react"
import { Script } from 'gatsby';
import { Helmet } from "react-helmet"
import { LWrap, CSectTitle } from "../../components/_index"
import MicroCmsContents from "./microcms_contents"

const BusinessLayout = ({ data, children }: { data: any, children?: React.ReactNode }) => {

  return (
    <>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function(){
    const sentenceAtag = document.querySelectorAll('.js_articleSentence a');
    for (let i = 0; i < sentenceAtag.length; i++) {
      const target = sentenceAtag[i].getAttribute('target')
      const icon_blank = document.createElement("i")
      icon_blank.className = 'icon-blank';
      const pdf_check = /\.pdf$/.test(sentenceAtag[i].getAttribute('href'))
      const icon_pdf = document.createElement("i")
      icon_pdf.className = 'icon-pdf';
      if(pdf_check){
        sentenceAtag[i].prepend(icon_pdf);
      }else if(target == "_blank"){
        sentenceAtag[i].appendChild(icon_blank);
      }
    }
})(document)
        `}} />
      <section className="l_sect">
        <LWrap size="small">
          <CSectTitle tag="h1" title={{ ja: data.title, en: "" }} />
          <MicroCmsContents data={data.contents} />
        </LWrap>
      </section>
    </>
  )
}
export default BusinessLayout;
