import * as React from "react";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components/_index";
import BusinessLayout from "../../components/_extra/business_layout";

// markup
const SubPage = ({ data }: { data: any }) => {
  const news = data.microcmsBusinessHotelstop;
  return (
    <Layout isKv={false}>
      <SEO
        title={news.title}
        description={news.description}
        meta={[
          {
            name: `robots`,
            content: `noindex`,
          },
        ]}
      />
      <BusinessLayout data={news} />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query BusinessDetail($id: String!) {
    microcmsBusinessHotelstop(id: { eq: $id }) {
      businessHotelstopId
      publishedAt
      title
      contents {
        fieldId
        heading_text
        wysiwyg
        box
        align
        caption
        label
        table
        youtube
        htmlcode
        type
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            align
            caption
            label
            table
            youtube
            htmlcode
            type
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          align
          caption
          label
          table
          youtube
          htmlcode
          type
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          align
          caption
          label
          table
          youtube
          htmlcode
          type
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`;
